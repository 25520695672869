
import User from '@/store/modules/User';
import { Component, Vue } from 'vue-property-decorator';
import { BvModalEvent } from 'bootstrap-vue';
import ErrorDisplay from '@/components/common/ErrorDisplay.vue';
import ApiError from '@/services/api/models/ApiError';

@Component({
  components: { ErrorDisplay }
})
export default class ConfirmPasswordModal extends Vue {
  public submitting = false;
  public error: string | null = null;
  public form = {
    password: ''
  };

  public async onSubmit(event: BvModalEvent): Promise<void> {
    event.preventDefault();
    if (this.form.password.length > 0 && User._currentUser != null) {
      this.submitting = true;
      User.confirmPassword({
        password: this.form.password
      })
        .then(response => {
          if (!response) {
            this.submitting = false;
            throw {
              status: 401,
              errorMessage: 'Unknown error'
            } as ApiError;
          }

          this.error = null;
          this.submitting = false;

          this.$bvModal.hide('verify-password');
          this.$bvModal.show('confirm-delete-account');
        })
        .catch(() => {
          this.error =
            'Failed to verify your password, please try again';
          this.submitting = false;
        });
    }
  }
}
