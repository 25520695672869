
import Component, { mixins } from 'vue-class-component';
import AppName from '../mixin/AppName.vue';

import FormTextInput, { InputAttributes } from '../common/FormTextInput.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    FormTextInput
  }
})
export default class AddRoleForm extends mixins(AppName) {

  @Prop() private onSubmit!: (roleName: string) => void;

  public roleName = '';
  public errorMessage: string | null = null;

  public get inputAttributes(): Partial<InputAttributes> {
    return {
      id: 'label',
      label: 'Role Name*',
      labelClass: 'text-primary',
      type: 'text',
      variant: this.getAppColour(true)
    };
  }

  public submit(): void {
    this.errorMessage = null;
    if (this.roleName.length === 0) {
      this.errorMessage = 'The role name is required';
      return;
    }
    if (this.roleName.length > 30) {
      this.errorMessage = 'The role name must be 30 characters or less';
      return;
    }
    this.onSubmit(this.roleName);
  }

}
