
import User from '@/store/modules/User';
import { Component, Vue } from 'vue-property-decorator';
import { BvModalEvent } from 'bootstrap-vue';
import RequestDeleteAccountRequest from '@/services/api/models/requests/RequestDeleteAccountRequest';
import ApiError from '@/services/api/models/ApiError';

@Component
export default class RequestDeleteAccountModal extends Vue {
  public submitting = false;
  public error: string | null = null;

  public async onSubmit(event: BvModalEvent): Promise<void> {
    event.preventDefault();
    if (User._currentUser == null) {
      return;
    }

    this.submitting = true;
    User.requestDeleteAccount({
      userId: User._currentUser.id,
      params: {
        callbackUrl: `${process.env.VUE_APP_PARTNER_URL}/auth/deleteAccount`
      }
    })
      .then(response => {
        if (!response) {
          this.submitting = false;
          throw {
            status: 500,
            errorMessage: 'Unknown error'
          } as ApiError;
        }

        this.error = null;
        this.submitting = false;

        this.$bvModal.hide('confirm-delete-account');
        this.$bvToast.toast(
          'You have been sent a confirmation email to proceed with deleting your account',
          {
            title: 'Success',
            toaster: 'b-toaster-bottom-center',
            solid: true,
            append: false,
            variant: 'success'
          }
        );
      })
      .catch((error: ApiError) => {
        this.error = `Failed to request account deletion: ${error.errorMessage}`;
        if (error.status == 403) {
          this.error +=
            ' - Please contact Levno if you still require your account to be deleted';
        }
        this.submitting = false;
      });
  }
}
