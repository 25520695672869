
import PhoneNumberInput from '@/components/items/PhoneNumberInput.vue';
import { trackEvent } from '@/services/Mixpanel';
import User from '@/store/modules/User';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import AddRoleForm from '@/components/settings/AddRoleForm.vue';
import ConfirmPasswordModal from '@/views/account/ConfirmPasswordModal.vue';
import RequestDeleteAccountModal from '@/views/account/RequestDeleteAccountModal.vue';

@Component({
  components: {
    RequestDeleteAccountModal,
    ConfirmPasswordModal,
    AddRoleForm,
    PhoneNumberInput
  },
  computed: {
    ...mapGetters('user', {
      currentUser: 'currentUser',
      token: 'token'
    })
  }
})
export default class Profile extends Vue {
  public submitted = false;
  public changed: string[] = [];
  public unresolvableError = false;
  public width = 1000;
  public form = {
    name: User.currentUser?.name,
    email: User.currentUser?.email,
    phone: User.currentUser?.phone
  };

  mounted() {
    trackEvent('User viewing profile');
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
  }

  destroyed() {
    window.removeEventListener('resize', this.updateWidth);
  }

  public updateWidth() {
    this.width = window.innerWidth;
  }

  public async onSubmit(event: any): Promise<void> {
    this.changed = [];
    this.submitted = false;
    event.preventDefault();
    const changedFields: { name?: string; email?: string; phone?: string } = {};
    if (this.form.name != User.currentUser?.name) {
      changedFields.name = this.form.name;
      this.changed.push('name');
    }
    if (this.form.email != User.currentUser?.email) {
      changedFields.email = this.form.email;
      this.changed.push('email');
    }
    if (this.form.phone != User.currentUser?.phone) {
      changedFields.phone = this.form.phone;
      this.changed.push('phone');
    } else {
      changedFields.phone = User.currentUser?.phone;
    }
    if (this.changed.length != 0) {
      await User.changeUserDetails({
        id: User._currentUser?.id ?? -1,
        params: changedFields
      }).then(response => {
        if (response == -1) {
          this.unresolvableError = true;
        }
        this.submitted = true;
        User.fetchCurrentUser();
        if (
          this.submitted &&
          this.changed.includes('email') &&
          !this.unresolvableError
        ) {
          this.$bvToast.toast(
            `Please check your email mailbox of ${changedFields.email} and verify the new email address`,
            {
              title: 'Email change verification sent!',
              toaster: 'b-toaster-bottom-center',
              solid: true,
              append: false
            }
          );
        } else if (this.unresolvableError) {
          const suggestion = this.changed.includes('email')
            ? 'Check that the email you have entered is not already taken'
            : 'Contact Levno to figure out what went wrong';
          this.$bvToast.toast(`Something went wrong. ${suggestion}`, {
            title: 'Something went wrong!',
            toaster: 'b-toaster-bottom-center',
            solid: true,
            append: false
          });
        }
      });
    }
  }

  public verifyPassword(): void {
    this.$bvModal.show('verify-password');
  }
}
